import React from "react";
import { handleToggleClick } from "../utils.js";

const ToggleField = ({
  value,
  setter,
  textStateOn,
  textStateOff,
  cookieName,
}) => {
  value = value === "true" || value === true;
  return (
    <label className="label cursor-pointer">
      <input
        type="checkbox"
        className="toggle toggle-lg toggle-primary bg-transparent"
        checked={value}
        onChange={handleToggleClick(value, setter, cookieName)}
      />
      <span>{`${value ? textStateOn : textStateOff}`}</span>
    </label>
  );
};

export default ToggleField;
