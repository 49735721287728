import React from "react";

const TextWithSpotifyLogo = ({
  textBefore = "",
  textAfter = "",
  logoColor = "Green",
  textColor = "White",
}) => {
  return (
    <div>
      <span
        className={`flex items-center ${
          textColor === "Green" ? "text-success" : null
        }`}
      >
        {textBefore}
        <img
          src={`${process.env.PUBLIC_URL}/Spotify_Logo_RGB_${logoColor}.png`}
          width={70}
          height={70}
          className={`${textBefore ? "ml-1" : ""}`}
        />
        {textAfter}
      </span>
    </div>
  );
};

export default TextWithSpotifyLogo;
