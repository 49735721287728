import React, { useContext } from "react";
import { AppContext } from "../App";
import TextWithSpotifyLogo from "./TextWithSpotifyLogo";
import { devMode, testPlaylist } from "../test-tracks";

const PlaylistPreview = () => {
  let { playlist } = useContext(AppContext);
  if (devMode) {
    playlist = testPlaylist;
  }
  if (!playlist.id) {
    return null;
  }
  return (
    <div
      key={playlist}
      className={`flex items-center cursor-pointer h-12  text-white`}
      onClick={() =>
        window.open(
          `https://open.spotify.com/playlist/${playlist.id}`,
          "_blank"
        )
      }
    >
      <div className="relative flex-shrink-0">
        <img
          className="w-12 h-12 object-cover"
          src={playlist.imageUrl}
        />
      </div>
      <div className="text-md ml-2 flex-grow">
        <div className={`font-semibold text-white w-56 truncate`}>
          {playlist.name}
        </div>
        <p className={`font-semibold text-success w-60`}>
          {/* {"[↗]"} */}
          <TextWithSpotifyLogo textBefore="Listen on" textColor="Green" />
        </p>
      </div>
    </div>
  );
};

export default PlaylistPreview;
