import React, { useState, useContext } from "react";
import { clearCookiesExcept, handleEventChange } from "../utils.js";
import { AppContext } from "../App";
import { getTracks } from "../utils.js";
import FieldWithTitle from "../Components/FieldWithTitle.jsx";
import { testTracks } from "../test-tracks.js";
import CookieButton from "../Components/CookieButton.jsx";
import TextWithSpotifyLogo from "../Components/TextWithSpotifyLogo.jsx";

const InputTracksCard = () => {
  const {
    tracks,
    setTracks,
    tracksState,
    setSampleFrom,
    setAmountToSample,
    advanced,
    tracksLoading,
    setTracksLoading,
    alwaysResample,
    setAlwaysResample,
  } = useContext(AppContext);

  const inputTracksButton = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex  mb-1 justify-center">
      <button
        onClick={getTracks(setTracks, tracksState, setTracksLoading)}
        className={`btn ${tracksLoading ? "loading" : ""} btn-primary ${
          tracks.length ? "btn-outline" : ""
        } btn-sm mt-1`}
      >
        {tracksLoading
          ? "Sampling Playlist"
          : tracks.length
          ? "New Sample From Playlist"
          : "Sample From Playlist"}
      </button>
    </div>
  );

  return (
    <div className="w-full">
      {advanced ? <CookieButton /> : null}
      <FieldWithTitle
        title="Playlist You Enjoy"
        display={true}
        type="textinput"
        value={tracksState.sampleFrom}
        setter={setSampleFrom}
        rows={1}
        alwaysDisplay
        titleSize="md"
        useLogo
      />

      <FieldWithTitle
        title="Amount to Sample"
        type="range"
        value={tracksState.amountToSample}
        setter={setAmountToSample}
      />

      <FieldWithTitle
        title="Songs You Enjoy"
        display={true}
        type="songlist"
        value={tracks}
        setter={setTracks}
        includeRemoveButton
        alwaysDisplay={true}
        noCookie
      />

      {!advanced ? inputTracksButton : null}
    </div>
  );
};

export default InputTracksCard;
