import React, { useContext } from "react";
import RecommendationsCard from "./RecommendationsCard.jsx";
import InputTracksCard from "./InputTracksCard.jsx";
import PlaylistCard from "./PlaylistCard.jsx";
import CustomInputCard from "./CustomPromptCard.jsx";
import "daisyui/dist/full.css";
import { AppContext } from "../App.js";
import CookieButton from "../Components/CookieButton.jsx";

const CardComponent = ({ type }) => {
  const {
    guidedMode,
    guidedModePage,
    setGuidedModePage,
    tracks,
    recommendations,
    advanced,
    setAdvanced,
    setGuidedMode,
    customize,
    setCustomize,
    customPrompt,
    allowCookies,
  } = useContext(AppContext);

  const missingTracks =
    (guidedModePage === 0 && tracks.length === 0) ||
    (guidedModePage === 2 && recommendations.length === 0);

  const title = (
    <div className="mb-2">
      <img
        src={`${process.env.PUBLIC_URL}/image.png`}
        alt="ENDLESS RECS Title"
        width={355}
      />

      <div className="text-primary text-center mb-5 text-xl">
        𝐎𝐧𝐞 𝐁𝐮𝐭𝐭𝐨𝐧 | 𝐈𝐧𝐟𝐢𝐧𝐢𝐭𝐞 𝐌𝐮𝐬𝐢𝐜
        {/* 𝐆𝐏𝐓 𝐏𝐨𝐰𝐞𝐫𝐞𝐝 𝐌𝐮𝐬𝐢𝐜 𝐑𝐞𝐜𝐨𝐦𝐦𝐞𝐧𝐝𝐚𝐭𝐢𝐨𝐧𝐬 */}
      </div>
    </div>
  );

  const previousButton = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex justify-center ml-3 mb-3">
      <button
        onClick={() => setGuidedModePage(guidedModePage - 1)}
        className="btn btn-sm btn-outline btn-primary "
      >
        ← Back
      </button>
    </div>
  );

  const nextButton = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex justify-center ml-auto mr-3 mb-3">
      <button
        onClick={() => setGuidedModePage(guidedModePage + 1)}
        className={`btn btn-sm ${
          !missingTracks && !advanced ? "" : "btn-outline"
        } btn-primary`}
      >
        {guidedModePage === 1 && !customPrompt ? "Skip" : "Next"}
      </button>
    </div>
  );
  const beginningButton = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex justify-center ml-auto mr-3 mb-3">
      <button
        onClick={() => setGuidedModePage(0)}
        className={`btn btn-sm btn-outline btn-primary`}
      >
        Beginning
      </button>
    </div>
  );

  const guideModePages = {
    input_tracks: [0, "① Choose Songs You Enjoy"],
    custom_input: [1, "② Write what vibe you like"],
    recommendations: [2, "③ Get Recommendations"],
    playlist: [3, "④ Add Songs To Playlist"],
  };

  const isGuidedMode =
    (guidedMode && guidedModePage === guideModePages[type][0]) || !guidedMode;

  const guidedModeButton = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex justify-center">
      <button
        onClick={() => {
          setGuidedMode(!guidedMode);
          setAdvanced(!advanced);
        }}
        className={`btn btn-outline btn-sm btn-primary  mb-4`}
      >
        {guidedMode ? "Four Panel Mode" : "Single Panel Mode"}
      </button>
    </div>
  );

  const advancedButton = (
    <button
      onClick={() => setAdvanced(!advanced)}
      className="btn btn-outline btn-sm btn-primary "
    >
      Advanced
    </button>
  );

  const backButton = (
    <button
      onClick={() =>
        advanced ? setAdvanced(!advanced) : setCustomize(!customize)
      }
      className={`btn btn-sm  ${
        !advanced ? "btn-outline" : ""
      }  btn-primary mr-24`}
    >
      {advanced ? "← Back" : "← Basic Mode"}
    </button>
  );

  const buttons = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex justify-center mt-6">
      {advanced ? backButton : null}
      {!advanced && advancedButton}
      {advanced && guidedModeButton}
    </div>
  );
  if (!isGuidedMode) {
    return null;
  }

  return (
    <div className="flex flex-col items-center text-white">
      <div className="text-xl font-bold mb-2">
        {guideModePages[type][0] === guidedModePage || !guidedMode
          ? guideModePages[type][1]
          : null}
      </div>
      <div>
        <div
          className={`card card-compact m-1 w-80 bg-ghost border border-primary shadow ${
            isGuidedMode ? "" : "hidden"
          }`}
        >
          <div className="card-body ">
            <div className="card-actions flex items-center">
              {type === "input_tracks" ? <InputTracksCard /> : null}
              {type === "custom_input" ? <CustomInputCard /> : null}
              {type === "recommendations" ? <RecommendationsCard /> : null}
              {type === "playlist" ? <PlaylistCard /> : null}
            </div>
          </div>
          {guidedMode && !advanced ? (
            <div className="flex justify-between items-center">
              {type !== "input_tracks" && previousButton}
              {type !== "playlist" && nextButton}
              {type === "playlist" && beginningButton}
            </div>
          ) : null}
        </div>
        {guidedMode ? buttons : null}
      </div>
    </div>
  );
};

export default CardComponent;
