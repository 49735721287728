import React, { useContext } from "react";
import { handleEventChange } from "../utils.js";
import { AppContext } from "../App";
import { getRecommendations } from "../utils.js";
import "daisyui/dist/full.css";
import FieldWithTitle from "../Components/FieldWithTitle.jsx";

const RecommendationsCard = () => {
  const {
    recommendations,
    setRecommendations,
    recommendationsState,
    temperature,
    setTemperature,
    setAmountToRecommend,
    advanced,
    setRecommendationsLoading,
    recommendationsLoading,
    ignoreInputTracks,
  } = useContext(AppContext);

  const updateRecommendationsButton = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex mb-1  justify-center">
      <button
        onClick={getRecommendations(
          recommendationsState,
          setRecommendations,
          setRecommendationsLoading,
          ignoreInputTracks
        )}
        className={`btn ${
          recommendationsLoading ? "loading" : ""
        } btn-primary ${
          recommendations.length ? "btn-outline" : ""
        } btn-sm mt-1`}
      >
        {recommendationsLoading
          ? "Generating"
          : recommendations.length
          ? "Get New Recommendations"
          : "Get Recommendations"}
      </button>
    </div>
  );

  return (
    <div className="w-full">
      <FieldWithTitle
        title="Temperature"
        type="range"
        setter={setTemperature}
        value={temperature}
        min={0}
        max={2}
        step={0.01}
      />
      <FieldWithTitle
        title="Number of Recs"
        type="range"
        setter={setAmountToRecommend}
        value={recommendationsState.amountToRecommend}
      />
      <FieldWithTitle
        title="Recommended Songs "
        display={true}
        type="songlist"
        setter={setRecommendations}
        value={recommendations}
        includeRemoveButton
        alwaysDisplay
        noCookie
      />
      {!advanced ? updateRecommendationsButton : null}
    </div>
  );
};

export default RecommendationsCard;
