import React, { useState, useContext } from "react";
import { handleEventChange } from "../utils.js";
import { AppContext } from "../App.js";
import { getTracks } from "../utils.js";
import FieldWithTitle from "../Components/FieldWithTitle.jsx";

const RangeField = ({
  setter,
  value,
  min = 1,
  max = 20,
  step = 1,
  cookieName,
}) => {
  const { allowCookies } = useContext(AppContext);
  return (
    <div className="flex items-center">
      <span className="mr-2 w-12 text-center text-lg">{value}</span>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        className="range range-md range-primary"
        value={value}
        onChange={handleEventChange(setter, cookieName, allowCookies)}
      />
    </div>
  );
};

export default RangeField;
