import React, { useState } from "react";
import { testTracks, devMode } from "../test-tracks";

const SongList = ({
  tracks,
  setTracks,
  includeRemoveButton = false,
  cookieName,
  large = false,
}) => {
  if (devMode) {
    tracks = testTracks;
  }
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const [audio, setAudio] = useState(null);

  const playPreview = (url, id) => {
    if (currentPlaying === id) {
      if (audio) {
        audio.pause();
        setCurrentPlaying(null);
      }
    } else {
      if (audio) {
        audio.pause();
      }

      if (url) {
        const newAudio = new Audio(url);
        newAudio.play();
        setAudio(newAudio);
        setCurrentPlaying(id);
      } else {
        alert("No preview available for this track.");
      }
    }
  };
  const removeTrack = (id) => {
    setTracks(tracks.filter((track) => track.id !== id));
    if (audio) {
      audio.pause();
    }
  };

  if (tracks.length === 0 || typeof tracks === "string") {
    return null;
  }

  const playIcon = (
    <img
      src={`${process.env.PUBLIC_URL}/play.png`}
      alt="Play"
      className={`w-4 h-3 `}
    />
  );

  const pauseIcon = (
    <img
      src={`${process.env.PUBLIC_URL}/pause.png`}
      alt="Pause"
      className={`w-4 h-3 `}
    />
  );

  const pausePlay = (track) => {
    return track.previewUrl
      ? currentPlaying === track.id
        ? pauseIcon
        : playIcon
      : null;
  };

  const spotifyIcon = (track) => {
    return (
      <img
        src={`${process.env.PUBLIC_URL}/Spotify_Icon_RGB_Green.png`}
        onClick={() => {
          window.open(`https://open.spotify.com/track/${track.id}`, "_blank");
          audio.pause();
        }}
        className=" w-4 h-4 mt-2 cursor-pointer"
      />
    );
  };

  return (
    <div
      className={`card ${large ? "" : ""} h-${large ? "80" : "60"} w-${
        large ? "72" : ""
      }`}
    >
      <div className="overflow-y-scroll">
        {tracks.length > 0
          ? tracks.map((track) => (
              <div className="flex">
                <div className="">
                  {spotifyIcon(track)}
                  <div className="w-5 h-3 mt-1">{pausePlay(track)}</div>
                </div>
                <div
                  key={track.id}
                  className={`flex items-center rounded-lg cursor-pointer h-12 ${
                    currentPlaying === track.id ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => playPreview(track.previewUrl, track.id)}
                >
                  <div className="relative flex-shrink-0">
                    <img
                      className="w-10 h-10 object-cover ml-1"
                      src={track.imageUrl}
                    />
                  </div>

                  <div className="text-sm ml-2 flex-grow">
                    <div
                      className={`font-semibold text-white truncate w-${
                        includeRemoveButton ? "40" : "52"
                      }`}
                      title={track.name}
                    >
                      {track.name}
                    </div>
                    <p
                      className={`font-semibold text-gray-500 truncate w-${
                        includeRemoveButton ? "40" : "52"
                      }`}
                      title={track.artists}
                    >
                      {track.artists}
                    </p>
                  </div>

                  {includeRemoveButton ? (
                    <button
                      className="btn btn-xs btn-outline text-gray-500 mr-4 ml-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTrack(track.id);
                      }}
                    >
                      ✕
                    </button>
                  ) : null}
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default SongList;
