import React, { useContext, useState } from "react";
import { handleEventChange } from "../utils.js";
import { AppContext } from "../App";
import { getRecommendations, createPlaylist } from "../utils.js";
import "daisyui/dist/full.css";
import FieldWithTitle from "../Components/FieldWithTitle.jsx";
import MultiSelect from "../Components/Multiselect.jsx";

const CustomPromptCard = () => {
  const {
    recommendationsState,
    setCustomPrompt,
    advanced,
    setRecommendations,
    setRecommendationsLoading,
    recommendationsLoading,
    ignoreInputTracks,
    setIgnoreInputTracks,
    excludeCovers,
    setExcludeCovers,
    excludeRepeatArtist,
    setExcludeRepeatArtist,
  } = useContext(AppContext);

  return (
    <div className="w-full">
      <FieldWithTitle
        title="Your Vibe (Optional)"
        display={true}
        type="textarea"
        value={recommendationsState.customPrompt}
        setter={setCustomPrompt}
        placeholder={
          'Examples:\n"lesser known songs"\n"more upbeat and guitar focussed"\n"obscure 60s banjo music"'
        }
        rows={4}
        alwaysDisplay
      />
      <FieldWithTitle
        title="Ignore Input Songs"
        type="toggle"
        value={ignoreInputTracks}
        setter={setIgnoreInputTracks}
        textStateOn="Ignore Input Songs"
        textStateOff="Include Input Songs"
      />
      {/* <FieldWithTitle
        title="Exclude Covers"
        type="toggle"
        value={excludeCovers}
        setter={setExcludeCovers}
        textStateOn="Exclude Covers"
        textStateOff="Allow Covers"
      /> */}
      <FieldWithTitle
        title="Exclude Artist Repeats"
        type="toggle"
        value={excludeRepeatArtist}
        setter={setExcludeRepeatArtist}
        textStateOn="Exclude Artist Repeats"
        textStateOff="Allow Artist Repeats"
        titleSize="base"
      />
    </div>
  );
};

export default CustomPromptCard;
