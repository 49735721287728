import React, { useContext } from "react";
import { getCookie, setCookie, clearCookiesExcept } from "../utils";
import { AppContext } from "../App";

const CookieButton = () => {
  const {
    allowCookies,
    setAllowCookies,
    showCookieModal,
    setShowCookieModal,
    setAccess_token_exists,
  } = useContext(AppContext);

  const handleCookieButtonClick = () => {
    const allowCookiesStored = getCookie("allow_cookies");
    const showCookieModal = getCookie("show_cookie_modal");

    if (allowCookiesStored === "true") {
      clearCookiesExcept([
        "token_set",
        "show_cookie_modal",
        "access_token",
        "refresh_token",
      ]);
    }

    if (showCookieModal === "false") {
      setCookie(
        "allow_cookies",
        allowCookiesStored === "true" ? "false" : "true"
      );
      setAllowCookies((prevState) => !prevState);
    } else {
      setShowCookieModal(true);
    }
  };

  const cookieModal = (
    <div
      className={`${
        getCookie("show_cookie_modal") === "false" ? "hidden" : ""
      }`}
    >
      <input
        type="checkbox"
        id="my-modal-6"
        className="modal-toggle"
        checked={showCookieModal}
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <label
            htmlFor="my-modal-6"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={() => {
              setCookie("allow_cookies", "false");
              setShowCookieModal(false);
              setAllowCookies(false);
            }}
          >
            ✕
          </label>
          <h3 className="font-bold text-lg">Save your settings with cookies</h3>
          <p className="py-4">
            Click "Allow Cookies" to allow your configuration to be remembered
            across multiple sessions. The information will not be shared or used
            for any other purpose but to remember your settings. You can opt out
            at any time by clicking the "Forget Settings" option.
          </p>
          <div className="modal-action">
            <label
              htmlFor="my-modal-6"
              className="btn btn-primary"
              onClick={() => {
                setCookie("allow_cookies", "true");
                setCookie("show_cookie_modal", "false");
                setAllowCookies(true);
              }}
            >
              Allow Cookies
            </label>
          </div>
        </div>
      </div>
    </div>
  );

  const logout = (
    <label
      onClick={() => {
        clearCookiesExcept([]);
        setAccess_token_exists(false);
      }}
      className={`btn btn-outline btn-sm shadow-xl btn-primary text-white mb-4 ml-4`}
    >
      {" "}
      Log out
    </label>
  );

  return (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex justify-center">
      {cookieModal}
      {/* <label htmlFor="my-modal-6" className="btn">open modal</label> */}
      <label
        onClick={() => handleCookieButtonClick()}
        className={`btn ${
          allowCookies ? "btn-outline" : ""
        } btn-sm shadow-xl btn-primary text-white mb-4`}
        htmlFor="my-modal-6"
        checked={allowCookies}
      >
        {allowCookies ? "Forget Settings" : "Remember Settings"}
      </label>
      {logout}
    </div>
  );
};

export default CookieButton;
