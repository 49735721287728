import React, { useContext, useEffect, useState } from "react";
import { infoContent } from "../infoContent";
import { AppContext } from "../App";
import { handleToggleClick, setCookie, getCookie } from "../utils";
import TextAreaField from "./TextAreaField";
import TextInputField from "./TextInputField";
import RangeField from "./RangeField";
import ToggleField from "./ToggleField";
import PlaylistPreview from "./PlaylistPreview";
import SongList from "./SongList";
import TextWithSpotifyLogo from "./TextWithSpotifyLogo";

const FieldWithTitle = ({
  title,
  display = false,
  type,
  value,
  setter,
  placeholder,
  rows = 5,
  min = 1,
  max = 20,
  step = 1,
  textStateOn,
  textStateOff,
  titleSize = "lg",
  includeRemoveButton,
  large,
  noInfo = false,
  alwaysDisplay = false,
  noCookie = false,
  useLogo = false,
}) => {
  const [displayField, setDisplayField] = useState(display);
  const [displayInfo, setDisplayInfo] = useState(false);
  const { advanced, recommendationsLoading, tracksLoading, allowCookies } =
    useContext(AppContext);
  const valueCookieTitle = title.replaceAll(" ", "_").toLowerCase();
  const displayCookieTitle =
    "display_" + title.replaceAll(" ", "_").toLowerCase();

  useEffect(() => {
    const displayCookie = getCookie(displayCookieTitle);
    if (displayCookie !== "") {
      setDisplayField(displayCookie === "true");
    }

    const valueCookie = decodeURIComponent(getCookie(valueCookieTitle));
    if (valueCookie !== "" && allowCookies) {
      if (!noCookie) {
        setter(valueCookie);
      }
    }
    if (valueCookie === "" && allowCookies) {
      setCookie(valueCookieTitle, encodeURIComponent(value));
    }
  }, [allowCookies]);

  // useEffect(() => {
  //   if (title === "INPUT TRACKS" || title === "RECOMMENDATIONS") {
  //     setCookie(
  //       title.replaceAll(" ", "_").toLowerCase(),
  //       encodeURIComponent(value)
  //     );
  //   }
  // }, [recommendationsLoading, tracksLoading]);

  const displayOrHideToggle = (
    <label className="label cursor-pointer">
      <input
        type="checkbox"
        className="toggle toggle-lg toggle-primary bg-transparent"
        checked={displayField}
        onChange={handleToggleClick(
          displayField,
          setDisplayField,
          displayCookieTitle,
          !displayField
        )}
      />
      <span>{`${displayField ? "DISPLAY" : "HIDE"}`}</span>
    </label>
  );

  const component = (
    <div>
      {type === "textarea" ? (
        <TextAreaField
          value={value}
          setter={setter}
          placeholder={placeholder}
          rows={rows}
          cookieName={valueCookieTitle}
        />
      ) : null}
      {type === "textinput" ? (
        <TextInputField
          value={value}
          setter={setter}
          cookieName={valueCookieTitle}
        />
      ) : null}
      {type === "range" ? (
        <RangeField
          value={value}
          setter={setter}
          min={min}
          max={max}
          step={step}
          cookieName={valueCookieTitle}
        />
      ) : null}
      {type === "toggle" ? (
        <ToggleField
          value={value}
          setter={setter}
          textStateOn={textStateOn}
          textStateOff={textStateOff}
          cookieName={valueCookieTitle}
        />
      ) : null}
      {type === "songlist" ? (
        <SongList
          tracks={value}
          setTracks={setter}
          cookieName={valueCookieTitle}
          includeRemoveButton={includeRemoveButton}
          large={large}
        />
      ) : null}
      {type === "playlist" ? <PlaylistPreview /> : null}
    </div>
  );

  const componentOrAdvanced = <div className="mb-5">{component}</div>;

  const titleWithInfoButton = (
    <div className="flex justify-between items-center mb-4  w-full max-w-xs">
      <h2 className={`text-${titleSize} font-bold`}>
        {useLogo ? (
          <TextWithSpotifyLogo textAfter={title} logoColor="White" />
        ) : (
          title
        )}
      </h2>
      {noInfo ? (
        ""
      ) : (
        <button
          className={`btn ${
            displayInfo ? "" : "btn-outline"
          } btn-sm shadow-xl btn-primary text-white`}
          onClick={() => setDisplayInfo(!displayInfo)}
        >
          {displayInfo ? "Info" : "Info"}
        </button>
      )}
    </div>
  );

  return (
    <div>
      {(!advanced && displayField) || (advanced && !alwaysDisplay) ? (
        <div className="w-full">
          {titleWithInfoButton}
          {displayInfo ? (
            <div className="card border border-primary p-2 text-base mb-4">
              {infoContent[title]}
            </div>
          ) : (
            componentOrAdvanced
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FieldWithTitle;
