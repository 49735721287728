export const infoContent = {
  "Songs You Enjoy": (
    <div>
      Song recommendations will be made based on a random batch of songs from
      the specified playlist above.
      <br /> <br />
      Press "Sample From Playlist" until you see a batch of songs you like,
      these then will be used as the basis for your song recommendations.
    </div>
  ),
  "Playlist You Enjoy": (
    <div>
      Choose a playlist from your library that you enjoy.
      <br /> <br />
      This will be the playlist that a random sample of songs will be pulled
      from when you press "Sample From Playlist" below.
    </div>
  ),
  "Amount to Sample": (
    <div>Choose the amount of songs to sample from the playlist. </div>
  ),
  "Your Vibe (Optional)": (
    <div>
      This optional input can be used to further customize your recommendations.
      <br /> <br />
      You can write anything you want to adjust the recommendations to your
      liking, for example: "catchier songs, more piano, lesser known"
      <br /> <br />
      Recommendations will be generated based on both "Songs You Enjoy" and
      "Your Vibe" collectively.
    </div>
  ),
  "Recommended Songs": (
    <div>
      These recommendations are generated based on a sample of songs from your
      Spotify.
      <br /> <br />
      Click on the playlist below to listen to them on Spotify.
      <br /> <br />
      Learn more and customize how the recommendations are returned by using
      "Custom Mode" below.
    </div>
  ),
  "Recommended Songs ": (
    <div>
      Press "Get Recommendations" to get song recommendations based on "Songs
      You Enjoy" and/or "Your Vibe".
      <br /> <br />
      Note that your results may vary, the recommendations are powered by
      OpenAI's GPT-4 which can be imperfect and you may get some repeated
      recommendations.
    </div>
  ),
  "Number of Recs": (
    <div>
      The number of recommendations to return.
      <br /> <br />
      Note that this field is imperfect and sometimes doesn't like to listen.
    </div>
  ),
  Temperature: (
    <div>
      Advanced field related to the randomness of the GPT-4 API.
      <br /> <br />
      From OpenAI's API Docs: "What sampling temperature to use, between 0 and
      2. Higher values like 0.8 will make the output more random, while lower
      values like 0.2 will make it more focused and deterministic."
    </div>
  ),
  "Playlist Title": (
    <div>
      Title of the playlist to update or create after pressing "Add Songs to
      Playlist".
      <br /> <br />
      If the playlist name is not found it will default to creating a new
      playlist.
    </div>
  ),
  Description: <div>Description to use if a new playlist is created.</div>,
  "Create or Update": (
    <div>
      Whether to create a new playlist or update an existing playlist each time
      "Add Songs To Playlist" is pressed.
      <br /> <br />
      If the playlist name is not found it will default to creating a new
      playlist even when using "Update Existing Playlist".",
    </div>
  ),
  "Your Playlist": (
    <div>This is the Spotify playlist your recommended songs were added to. </div>
  ),
  "Skip Repeat Tracks": (
    <div>
      Recommendations often can contain songs you've been recommended before.
      <br /> <br />
      Choose whether to skip adding songs that are already in the playlist.
    </div>
  ),
  "Ignore Input Songs": (
    <div>
      By default, recommendations will come from a combination of your specified
      songs in the first panel and your vibe in the current panel.
      <br /> <br />
      Use can use this option to exlusively get recommendations based on your
      specified vibe, ignoring the "Songs You Enjoy".
    </div>
  ),
  "Exclude Covers": (
    <div>
      Control whether to allow cover songs to be included in the
      recommendations.
    </div>
  ),
  "Exclude Artist Repeats": (
    <div>
      Control whether to allow the recommendations to include multiple
      recommendations from the same artist.
    </div>
  ),
};
