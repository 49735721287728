import { useState, createContext, useEffect } from "react";
import axios from "axios";
import "daisyui/dist/full.css";
import Card from "./Cards/Card.jsx";
import SpotifyPlaylist from "./Components/SpotifyPlaylist.jsx";
import SongList from "./Components/SongList.jsx";
import { testTracks, devMode } from "./test-tracks.js";
import FieldWithTitle from "./Components/FieldWithTitle.jsx";
import Button from "./Components/Button.jsx";
import CookieButton from "./Components/CookieButton.jsx";

import {
  BACKEND_URL,
  accessTokenSet,
  resampleGeneratePlaylist,
  setCookie,
  getCookie,
  getUserPlaylists,
} from "./utils.js";
import TextWithSpotifyLogo from "./Components/TextWithSpotifyLogo.jsx";
axios.defaults.withCredentials = true;

export const AppContext = createContext();

function App() {
  const [tracks, setTracks] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [customize, setCustomize] = useState(true);
  const [advanced, setAdvanced] = useState(false);
  const [allowCookies, setAllowCookies] = useState(false);
  const [temperature, setTemperature] = useState(0.9);
  const [playlistName, setPlaylistName] = useState("ENDLESS RECS");
  const [playlistDescription, setPlaylistDescription] = useState(
    "GPT powered playlist generated at endless-recs.com"
  );
  const [playlistCreateNew, setPlaylistCreateNew] = useState(false);
  const [sampleFrom, setSampleFrom] = useState("Liked Songs");
  const [amountToSample, setAmountToSample] = useState(8);
  const [customPrompt, setCustomPrompt] = useState("");
  const [amountToRecommend, setAmountToRecommend] = useState(10);
  const [resampleGeneratePlaylistLoading, setResampleGeneratePlaylistLoading] =
    useState(false);
  const [recommendationsLoading, setRecommendationsLoading] = useState(false);
  const [tracksLoading, setTracksLoading] = useState(false);
  const [createPlaylistLoading, setCreatePlaylistLoading] = useState(false);
  const [skipRepeats, setSkipRepeats] = useState(true);
  const [playlist, setPlaylist] = useState("");
  const [allPlaylists, setAllPlaylists] = useState([]);
  const [playlistImageUrl, setPlaylistImageUrl] = useState("");

  const [ignoreInputTracks, setIgnoreInputTracks] = useState(false);
  const [alwaysResample, setAlwaysResample] = useState(true);
  const [excludeCovers, setExcludeCovers] = useState(true);
  const [excludeRepeatArtist, setExcludeRepeatArtist] = useState(true);

  const [guidedMode, setGuidedMode] = useState(true);
  const [guidedModePage, setGuidedModePage] = useState(0);

  const [showCookieModal, setShowCookieModal] = useState(false);
  const [access_token_exists, setAccess_token_exists] = useState(
    accessTokenSet()
  );

  useEffect(() => {
    const allow_cookies = getCookie("allow_cookies");
    setAllowCookies(allow_cookies === "true");
    getUserPlaylists(setAllPlaylists)();
  }, []);

  const tracksState = { sampleFrom, amountToSample };
  const recommendationsState = {
    tracks,
    temperature,
    customPrompt,
    amountToRecommend,
    excludeCovers,
    excludeRepeatArtist,
  };
  const playlistState = {
    playlistName,
    playlistDescription,
    playlistCreateNew,
    skipRepeats,
  };

  const title = (
    <div className="mb-2">
      <img
        src={`${process.env.PUBLIC_URL}/image.png`}
        alt="ENDLESS RECS Title"
        width={355}
      />
    </div>
  );

  const loginWithSpotify = (
    <div className="flex flex-col justify-center items-center">
      {title}
      <button
        onClick={() => window.location.assign(`${BACKEND_URL}/login`)}
        className="btn btn-success mb-4"
      >
        <TextWithSpotifyLogo
          textBefore="Login with"
          textColor="Black"
          logoColor="Black"
        />
      </button>
    </div>
  );

  const generateRecsButton = (
    <div className="mb-4">
      <button
        onClick={resampleGeneratePlaylist(
          setTracks,
          recommendationsState,
          setRecommendations,
          playlistState,
          tracksState,
          setResampleGeneratePlaylistLoading,
          setTracksLoading,
          setRecommendationsLoading,
          setCreatePlaylistLoading,
          setPlaylist,
          ignoreInputTracks,
          alwaysResample
        )}
        className={`btn btn-lg ${
          resampleGeneratePlaylistLoading ? "loading" : ""
        } btn-primary mb-2`}
        style={{ width: "300px" }}
      >
        {tracksLoading
          ? "Sampling Your Spotify"
          : recommendationsLoading
          ? "Suggesting Songs"
          : createPlaylistLoading
          ? "Adding Songs To Playlist"
          : recommendations.length
          ? "Get More Recommendations"
          : "Get Recommendations"}
      </button>
    </div>
  );

  const songListSection =
    playlist.id || devMode ? (
      <div
        className={`card border-primary border p-2  w-80 mb-8 text-white p-4`}
      >
        {/* <SongList tracks={testTracks} setTracks={setTracks} large /> */}
        <FieldWithTitle
          title="Recommended Songs"
          display={true}
          type="songlist"
          value={recommendations}
          setter={setRecommendations}
          large
          noCookie
        />
        <FieldWithTitle
          title="Songs Added To Playlist"
          display={true}
          type="playlist"
          noInfo
          noCookie
        />
      </div>
    ) : null;

  const generateRecommendationsPage = (
    <div className="flex flex-col justify-center items-center ">
      {title}
      {songListSection}
      {generateRecsButton}
      <div className="mb-4">
        <button
          onClick={() => setCustomize(!customize)}
          className="btn btn-outline btn-sm btn-primary btn:hover"
        >
          Custom Mode
        </button>
      </div>
    </div>
  );

  const guidedModeButton = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex justify-center">
      <button
        onClick={() => {
          setGuidedMode(!guidedMode);
          setAdvanced(!advanced);
        }}
        className={`btn btn-outline btn-sm btn-primary  mb-4`}
      >
        {guidedMode ? "Four Panel Mode" : "Single Panel Mode"}
      </button>
    </div>
  );

  const advancedButton = (
    <button
      onClick={() => setAdvanced(!advanced)}
      className="btn btn-outline btn-sm btn-primary "
    >
      Advanced
    </button>
  );

  const backButton = (
    <button
      onClick={() =>
        advanced ? setAdvanced(!advanced) : setCustomize(!customize)
      }
      className={`btn btn-sm  ${
        !advanced ? "btn-outline" : ""
      }  btn-primary  mr-20`}
    >
      {advanced ? "← Back" : "← Basic Mode"}
    </button>
  );

  const buttons = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex justify-center mb-6">
      {advanced ? backButton : null}
      {!advanced && advancedButton}
      {advanced && guidedModeButton}
    </div>
  );

  const customizeCards = (
    <div
      className={`flex flex-col justify-center items-center ${
        access_token_exists && customize ? "" : "hidden"
      }`}
    >
      <div className={`text-primary text-3xl font-bold mb-2 mt-4`}>
        {!advanced ? title : "🅰🅳🆅🅰🅽🅲🅴🅳"}
      </div>
      <div
        className={`grid grid-cols-1 ${
          guidedMode ? "" : "md:grid-cols-2 xl:grid-cols-4"
        } gap-4 justify-items-center mb-4`}
      >
        <Card type="input_tracks" />
        <Card type="custom_input" />
        <Card type="recommendations" />
        <Card type="playlist" />
      </div>
      {!guidedMode ? buttons : null}
    </div>
  );

  return (
    <AppContext.Provider
      value={{
        tracks,
        setTracks,
        recommendations,
        setRecommendations,
        recommendationsState,
        temperature,
        setTemperature,
        playlistState,
        setPlaylistName,
        setPlaylistDescription,
        setPlaylistCreateNew,
        tracksState,
        setSampleFrom,
        setAmountToSample,
        setCustomPrompt,
        setAmountToRecommend,
        advanced,
        setAdvanced,
        resampleGeneratePlaylistLoading,
        setResampleGeneratePlaylistLoading,
        recommendationsLoading,
        setRecommendationsLoading,
        tracksLoading,
        setTracksLoading,
        createPlaylistLoading,
        setCreatePlaylistLoading,
        playlist,
        setPlaylist,
        skipRepeats,
        setSkipRepeats,
        allowCookies,
        setAllowCookies,
        ignoreInputTracks,
        setIgnoreInputTracks,
        alwaysResample,
        setAlwaysResample,
        excludeCovers,
        setExcludeCovers,
        excludeRepeatArtist,
        setExcludeRepeatArtist,
        guidedMode,
        setGuidedMode,
        guidedModePage,
        setGuidedModePage,
        setCustomize,
        customize,
        customPrompt,
        showCookieModal,
        setShowCookieModal,
        allPlaylists,
        sampleFrom,
        access_token_exists,
        setAccess_token_exists,
      }}
    >
      <div
        className={`grid place-items-center overflow-auto bg-black ${
          guidedMode ? "absolute inset-0" : "min-h-screen"
        }`}
      >
        {!access_token_exists && loginWithSpotify}
        {/* {access_token_exists && !customize && generateRecommendationsPage} */}
        {customizeCards}
      </div>
    </AppContext.Provider>
  );
}

export default App;
