import React, { useState, useContext } from "react";
import { handleEventChange } from "../utils.js";
import { AppContext } from "../App.js";
import { getTracks } from "../utils.js";
import FieldWithTitle from "../Components/FieldWithTitle.jsx";

const TextAreaField = ({
  setter,
  value,
  placeholder,
  rows = 5,
  cookieName,
}) => {
  const { allowCookies } = useContext(AppContext);
  return (
    <textarea
      className={`textarea textarea-sm bg-transparent textarea-primary w-full max-w-xs `}
      onChange={handleEventChange(setter, cookieName, allowCookies)}
      value={value}
      rows={rows}
      placeholder={placeholder}
      maxLength="170"
    />
  );
};

export default TextAreaField;
