import React, { useState, useContext, useEffect } from "react";
import { handleEventChange } from "../utils.js";
import { AppContext } from "../App.js";
import { getTracks } from "../utils.js";
import FieldWithTitle from "../Components/FieldWithTitle.jsx";
import { testAllPlaylists } from "../test-tracks.js";
import { devMode } from "../test-tracks.js";

function removeObjectById(objects, targetId) {
  return objects.filter((object) => object.id !== targetId);
}
const TextInputField = ({ setter, value, cookieName }) => {
  let { allowCookies, allPlaylists, setPlaylist } = useContext(AppContext);
  if (devMode) {
    allPlaylists = testAllPlaylists;
  }

  const likedSongs = {
    id: "tracks",
    name: "Liked Songs",
    imageUrl: "https://t.scdn.co/images/3099b3803ad9496896c43f22fe9be8c4.png",
    url: "https://open.spotify.com/collection/tracks",
  };

  useEffect(() => {
    if (
      cookieName !== "playlist_title" &&
      !allPlaylists.some((playlist) => playlist.id === "tracks")
    ) {
      allPlaylists.unshift(likedSongs);
    }
  }, [allPlaylists]);
  const [filteredPlaylists, setFilteredPlaylists] = useState([]);

  useEffect(() => {
    setFilteredPlaylists(
      allPlaylists.filter((playlist) => {
        if (
          cookieName === "playlist_title" &&
          playlist.name === "Liked Songs"
        ) {
          return false;
        }
        return playlist.name.toLowerCase().includes(value.toLowerCase());
      })
    );
  }, [value, allPlaylists]);

  // const handleDropdownItemClick = (playlistName) => {
  //   console.log(playlistName)
  //   setter(playlistName);

  //   if (cookieName === "playlist_title") {
  //     setPlaylist(
  //       allPlaylists.find((playlist) => playlist.name === playlistName) || ""
  //     );
  //   }
  //   const elem = document.activeElement;
  //   if (elem) {
  //     elem?.blur();
  //   }
  // };
  // const handleDropdownClick = (e) => {

  //   const listItem = e.target.closest("li.playlist-item");
  //   console.log(listItem.dataset)
  //   if (listItem) {
  //     handleDropdownItemClick(listItem.dataset);
  //   }
  // };
  const spotifyIcon = (playlist) => {
    return (
      <img
        src={`${process.env.PUBLIC_URL}/Spotify_Icon_RGB_Green.png`}
        onClick={() => {
          window.open(
            `https://open.spotify.com/${
              playlist.id === "tracks" ? "collection" : "playlist"
            }/${playlist.id}`,
            "_blank"
          );
        }}
        className=" w-4 h-4 mt-3 ml-1 mr-1 cursor-pointer"
      />
    );
  };
  const handleDropdownItemClick = (playlistName) => {
    setter(playlistName);
    if (cookieName === "playlist_title") {
      setPlaylist(
        allPlaylists.find((playlist) => playlist.name === playlistName) || ""
      );
    }
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };

  const handleDropdownClick = (e) => {
    const listItem = e.target.closest("li.playlist-item");
    if (listItem) {
      handleDropdownItemClick(listItem.dataset.playlistName);
    }
  };

  const [touchMoved, setTouchMoved] = useState(false);
  const handleTouchMove = (e) => {
    setTouchMoved(true);
  };

  const handleTouchStart = (e) => {
    setTouchMoved(false);
  };

  const handleTouchEnd = (e) => {
    if (!touchMoved) {
      handleDropdownClick(e);
    }
  };

  return (
    <div className="dropdown dropdown-end w-full">
      <input
        type="text"
        className="input input-bordered bg-transparent dropdown-toggle input-primary w-full max-w-xs"
        value={value}
        onChange={(event) => {
          if (cookieName === "playlist_title") {
            setPlaylist("");
          }
          handleEventChange(setter, cookieName, allowCookies)(event);
        }}
      />

      <ul
        className="dropdown-content w-full max-w-xs border border-primary max-h-44 overflow-auto bg-black rounded-md p-1"
        onClick={handleDropdownClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        {filteredPlaylists.map((playlist) => (
          <li
            key={playlist.id}
            className="mt-2 playlist-item"
            data-playlist-name={playlist.name}
          >
            <button className="dropdown-item w-full">
              <div className="flex w-64">
                {spotifyIcon(playlist)}
                <img
                  className="w-10 h-10 object-cover ml-1"
                  src={playlist.imageUrl}
                />
                <div className="truncate ml-2 mt-2 font-semibold">
                  {playlist.name}
                </div>
              </div>
            </button>
          </li>
        ))}
        {filteredPlaylists.length !== 0 ? null : cookieName ===
          "playlist_title" ? (
          <div>{`Create new playlist: ${value}`}</div>
        ) : (
          <div className="text-gray-500">No matching playlist</div>
        )}
      </ul>
    </div>
  );
};

export default TextInputField;
