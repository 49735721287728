import React, { useContext } from "react";
import {
  handleEventChange,
  createPlaylist,
  handleToggleClick,
} from "../utils.js";
import { AppContext } from "../App";
import FieldWithTitle from "../Components/FieldWithTitle.jsx";
import SpotifyPlaylist from "../Components/SpotifyPlaylist.jsx";

const PlaylistCard = () => {
  const {
    recommendations,
    playlistState,
    setPlaylistName,
    setPlaylistDescription,
    setPlaylistCreateNew,
    createPlaylistLoading,
    setCreatePlaylistLoading,
    playlist,
    setPlaylist,
    skipRepeats,
    setSkipRepeats,
    advanced,
  } = useContext(AppContext);

  const createPlaylistButton = (
    <div className="col-span-full md:col-span-2 xl:col-span-3 flex  mb-1  justify-center">
      <button
        onClick={createPlaylist(
          recommendations,
          playlistState,
          setCreatePlaylistLoading,
          setPlaylist
        )}
        className={`btn ${
          createPlaylistLoading ? "loading" : ""
        } btn-sm btn-primary place-items-center mt-1`}
      >
        {createPlaylistLoading ? "Adding To Playlist" : "Add Songs To Playlist"}
      </button>
    </div>
  );

  return (
    <div className="w-full">
      <FieldWithTitle
        title="Playlist Title"
        type="textinput"
        setter={setPlaylistName}
        value={playlistState.playlistName}
        rows={1}
        display
        alwaysDisplay
      />
      <FieldWithTitle
        title="Description"
        type="textarea"
        setter={setPlaylistDescription}
        value={playlistState.playlistDescription}
        rows={2}
      />
      {/* <FieldWithTitle
        title="Create or Update"
        type="toggle"
        setter={setPlaylistCreateNew}
        value={playlistState.playlistCreateNew}
        textStateOn="Create New Playlist"
        textStateOff="Update Existing Playlist"
      /> */}
      <FieldWithTitle
        title="Skip Repeat Tracks"
        type="toggle"
        setter={setSkipRepeats}
        value={skipRepeats}
        textStateOn="Skip Repeat Tracks"
        textStateOff="Add Repeat Tracks"
      />
      <FieldWithTitle
        title="Your Playlist"
        type="playlist"
        display
        alwaysDisplay
        noCookie
      />
      {!advanced ? createPlaylistButton : null}
    </div>
  );
};

export default PlaylistCard;
